<template>
  <div class="idle"></div>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'debounce'

export default {
  name: 'IdleLogout',
  props: {
    logoutAfter: {
      type: Number,
      default: 20
    },
    checkInterval: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      resetInteractionEvents: ['keydown', 'touchstart', 'mousedown', 'scroll'],
      interval: undefined
    }
  },
  computed: {
    ...mapState({
      loggedIn: state => state.authentication.loggedIn,
      rememberMe: state => state.authentication.rememberMe || false
    })
  },
  created() {
    if (!this.checkLogoutTimeout()) {
      this.resetInteractionEvents.forEach(event => {
        document.addEventListener(event, this.resetLastInteractionTimer)
      })
      this.interval = setInterval(this.checkLogoutTimeout, this.checkInterval)
    }
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  methods: {
    resetLastInteractionTimer: debounce(
      function () {
        if (!this.checkLogoutTimeout()) {
          window.sessionStorage.setItem('lastInteraction', Date.now())
        }
      },
      500,
      true
    ),
    checkLogoutTimeout() {
      if (this.loggedIn && !this.rememberMe) {
        const difference = Math.abs(
          Date.now() - window.sessionStorage.getItem('lastInteraction')
        )

        if (difference > this.logoutAfter * 60 * 1000) {
          this.$store.dispatch('reset')
          this.$router.push({
            name: 'Login',
            query: {
              redirect: window.location.pathname,
              reason: 'autologout'
            }
          })

          return true
        }
      }

      return false
    }
  }
}
</script>

<style scoped>
.idle {
  display: none;
}
</style>
